import { Navigate, Route, RouteProps } from 'react-router-dom';
import React, { ReactElement, LazyExoticComponent } from 'react';

import { RolesTypeEnum, UserRolesEnum } from '../lib/enums/UserAccount';
import { ServiceChannelsEnum } from '../lib/enums/ServiceChannels';

// lazy load all the views
const Venues = React.lazy(() => import('../pages/venues'));
const Accounts = React.lazy(() => import('../pages/accounts'));
const Products = React.lazy(() => import('../pages/products'));
const Settings = React.lazy(() => import('../pages/settings'));
const Customers = React.lazy(() => import('../pages/customers'));
// const RentalPays = React.lazy(() => import('../pages/rentalpays'));
const VirtualPOS = React.lazy(() => import('../pages/virtualPOS'));
const BankCardTransactions = React.lazy(
  () => import('../pages/transactions/BankCardTransactions')
);
const WalletTransactions = React.lazy(
  () => import('../pages/transactions/WalletTransactions')
);
const Liquidations = React.lazy(() => import('../pages/liquidations'));
const Subscriptions = React.lazy(() => import('../pages/subscriptions'));

const CreateSubscription = React.lazy(() => import('../pages/subscriptions/CreateSubscription'));
const SubscriptionDetails = React.lazy(() => import('../pages/subscriptions/details'));

const RecurringCharges = React.lazy(() => import('../pages/recurringCharges'));

const Blacklist = React.lazy(() => import('../pages/blacklist'));
const CreateAccount = React.lazy(
  () => import('../pages/accounts/createAccount')
);
const ClosuresConciliation = React.lazy(
  () => import('../pages/reports/closuresConciliations')
);
const CommissionReport = React.lazy(
  () => import('../pages/reports/commissionReport')
);

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const RegisterMyAccount = React.lazy(
  () => import('../pages/auth/RegisterMyAccount')
);
const ConfirmAccount = React.lazy(() => import('../pages/account/Confirm'));
const ForgotPassword = React.lazy(() => import('../pages/auth/ForgotPassword'));
const RecoveryPassword = React.lazy(
  () => import('../pages/auth/RecoveryPassword')
);
const NotFound = React.lazy(() => import('../pages/account/notFound'));
const PaymentResult = React.lazy(() => import('../pages/virtualPOS/PaymentResult'));

//public
const ProcessLinkPayment = React.lazy(
  () => import('../pages/virtualPOS/processLinkPayment')
);
const VerifyCode = React.lazy(() => import('../pages/virtualPOS/VerifyCode'));

/** Settings views **/
const Banks = React.lazy(() => import('../pages/settings/banks'));
const Wallets = React.lazy(() => import('../pages/settings/wallets'));
const Categories = React.lazy(() => import('../pages/settings/categories'));
const Channels = React.lazy(() => import('../pages/settings/channels/index'));
const SelectSettings = React.lazy(() => import('../pages/settings/Settings'));
const Countries = React.lazy(() => import('../pages/settings/countries/index'));
const IssuerBins = React.lazy(
  () => import('../pages/settings/issuerBins/index')
);
const Currencies = React.lazy(
  () => import('../pages/settings/currencies/index')
);
const PaymentMethods = React.lazy(
  () => import('../pages/settings/paymentMethods/index')
);
const SecurityProviders = React.lazy(
  () => import('../pages/settings/securityProviders/index.js')
);
const UsersAccountsACL = React.lazy(
  () => import('../pages/settings/UsersAccount')
);
const GlobalConfigurations = React.lazy(
  () => import('../pages/settings/globalConfigs')
);

const SettingsPromotions = React.lazy(() => import('../pages/settings/promotions'))
const Tickets = React.lazy(() => import('../pages/tickets'))

export interface ACLRoutesProps {
  path: RouteProps['path'];
  name?: string;
  channels?: ServiceChannelsEnum[];
  element?:
  | LazyExoticComponent<() => ReactElement>
  | RouteProps['element']
  | any;
  route?: any;
  exact?: boolean;
  icon?: string;
  header?: string;
  roles?: UserRolesEnum[];
  roleTypes?: RolesTypeEnum[];
  children?: ACLRoutesProps[];
}

const authRoutes: ACLRoutesProps[] = [
  {
    path: '/auth/login',
    name: 'Login',
    element: Login,
    route: Route
  },
  {
    path: '/auth/logout',
    name: 'Logout',
    element: Logout,
    route: Route
  },
  {
    path: '/auth/forgot-password',
    name: 'forgotPassword',
    element: ForgotPassword,
    route: Route
  },
  {
    path: '/auth/register-account',
    name: 'register',
    element: RegisterMyAccount,
    route: Route
  },
  {
    path: '/auth/recovery-password/:hash',
    name: 'recoveryPassword',
    element: RecoveryPassword,
    route: Route
  },
  {
    path: '/confirm',
    name: 'confirmRegister',
    element: ConfirmAccount,
    route: Route
  }
];
const otherPublicRoutes: ACLRoutesProps[] = [
  {
    name: '/notFound',
    path: 'notFound',
    element: NotFound,
    route: Route
  },
  {
    path: '/checkout/:_id',
    name: 'checkoutPaymentLink',
    element: ProcessLinkPayment,
    route: Route
  },
  {
    path: '/payment/result/:_id',
    name: 'PaymentResult',
    element: PaymentResult,
    route: Route
  },
  {
    path: '/verify-code/:_id',
    name: 'checkoutPaymentLink',
    element: VerifyCode,
    route: Route
  }
];

const dashboard: ACLRoutesProps[] = [
  {
    path: '/',
    name: 'transactions',
    element: () => <Navigate to="/transactions/bank" />,
    roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.ADMIN_USER],
    channels: [
      ServiceChannelsEnum.API_AVAILABLE,
      ServiceChannelsEnum.YAP_AVAILABLE,
      ServiceChannelsEnum.SUBSCRIPTION_AVAILABLE,
      ServiceChannelsEnum.LINK_AVAILABLE
    ]
  },
  {
    path: '/transactions/bank',
    name: 'transactions',
    element: BankCardTransactions,
    roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.ADMIN_USER],
    channels: [
      ServiceChannelsEnum.API_AVAILABLE,
      ServiceChannelsEnum.YAP_AVAILABLE,
      ServiceChannelsEnum.SUBSCRIPTION_AVAILABLE,
      ServiceChannelsEnum.LINK_AVAILABLE
    ]
  },
  {
    path: '/transactions/eWallet',
    name: 'walletTransactions',
    element: WalletTransactions,
    roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.ADMIN_USER],
    channels: [
      ServiceChannelsEnum.API_AVAILABLE,
      ServiceChannelsEnum.YAP_AVAILABLE,
      ServiceChannelsEnum.SUBSCRIPTION_AVAILABLE,
      ServiceChannelsEnum.LINK_AVAILABLE
    ]
  },
  {
    path: '/liquidations',
    name: 'liquidations',
    element: Liquidations,
    roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.ADMIN_USER],
    channels: [
      ServiceChannelsEnum.API_AVAILABLE,
      ServiceChannelsEnum.YAP_AVAILABLE,
      ServiceChannelsEnum.SUBSCRIPTION_AVAILABLE,
      ServiceChannelsEnum.LINK_AVAILABLE
    ]
  },
  {
    path: '/reports/closures',
    name: 'closures',
    element: ClosuresConciliation,
    roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.ADMIN_USER]
  },
  {
    path: '/reports/payments',
    name: 'commissionsReport',
    element: CommissionReport,
    roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.ADMIN_USER]
  },
  {
    path: '/blacklist',
    name: 'blacklist',
    element: Blacklist,
    roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.ADMIN_USER]
  },
  {
    path: '/accounts',
    name: 'accounts',
    element: Accounts,
    roles: [UserRolesEnum.SUPER_USER]
  },
  {
    path: '/accounts/create',
    name: 'createAccount',
    element: CreateAccount,
    roles: [UserRolesEnum.SUPER_USER]
  },
  {
    path: '/accounts/:accountID/terminals',
    name: 'accountTerminals',
    element: Accounts,
    roles: [UserRolesEnum.SUPER_USER]
  },
  {
    path: '/accounts/:accountID/terminals/:terminalID',
    name: 'accountTerminals',
    element: Accounts,
    roles: [UserRolesEnum.SUPER_USER]
  },
  {
    path: '/venues',
    name: 'venues',
    element: Venues,
    roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.ADMIN_USER],
    channels: [ServiceChannelsEnum.YAP_AVAILABLE]
  },
  {
    path: '/virtualPOS',
    name: 'virtualPOS',
    element: VirtualPOS,
    roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.ADMIN_USER]
  },
  {
    path: '/subscriptions',
    name: 'subscriptions',
    element: Subscriptions,
    roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.ADMIN_USER]
  },
  {
    path: '/subscriptions/create',
    name: 'createSubscriptions',
    element: CreateSubscription,
    roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.ADMIN_USER]
  },
  {
    path: '/subscriptions/:id/details',
    name: 'detailSubscription',
    element: SubscriptionDetails,
    roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.ADMIN_USER]
  },
  {
    path: 'recurring-charges',
    name: 'recurringCharges',
    element: RecurringCharges,
    roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.ADMIN_USER]
  },
  {
    path: '/customers',
    name: 'customers',
    element: Customers,
    roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.ADMIN_USER]
  },
  // {
  //   path: '/rentalpays',
  //   name: 'rentalpays',
  //   element: RentalPays,
  //   roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.ADMIN_USER]
  // },
  {
    path: '/products',
    name: 'products',
    element: Products,
    roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.ADMIN_USER]
  },
  {
    path: '/settings',
    name: 'settings',
    element: SelectSettings,
    roles: [UserRolesEnum.SUPER_USER]
  },
  {
    path: '/settings/profile',
    name: 'settings',
    element: Settings,
    roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.ADMIN_USER]
  },
  {
    path: '/settings/users',
    name: 'userSettings',
    element: UsersAccountsACL,
    roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.ADMIN_USER]
  },
  {
    path: '/settings/banks',
    name: 'banks',
    element: Banks,
    roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.ADMIN_USER]
  },
  {
    path: '/settings/categories',
    name: 'categories',
    element: Categories,
    roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.ADMIN_USER]
  },
  {
    path: '/settings/wallets',
    name: 'wallets',
    element: Wallets,
    roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.ADMIN_USER]
  },
  {
    path: '/settings/paymentMethods',
    name: 'paymentMethods',
    element: PaymentMethods,
    roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.ADMIN_USER]
  },
  {
    path: '/settings/channels',
    name: 'channels',
    element: Channels,
    roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.ADMIN_USER]
  },
  {
    path: '/settings/issuerBins',
    name: 'issuerBins',
    element: IssuerBins,
    roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.ADMIN_USER]
  },
  {
    path: '/settings/countries',
    name: 'countries',
    element: Countries,
    roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.ADMIN_USER]
  },
  {
    path: '/settings/currencies',
    name: 'currencies',
    element: Currencies,
    roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.ADMIN_USER]
  },
  {
    path: '/settings/securityProviders',
    name: 'securityProviders',
    element: SecurityProviders,
    roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.ADMIN_USER]
  },
  {
    path: '/settings/global',
    name: 'globalSettings',
    element: GlobalConfigurations,
    roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.ADMIN_USER]
  },
  {
    path: '/settings/promotions',
    name: 'promotions',
    element: SettingsPromotions,
    roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.ADMIN_USER]
  },
  {
    path: '/tickets',
    name: 'tickets',
    element: Tickets,
    roles: [UserRolesEnum.SUPER_USER, UserRolesEnum.TICKET_USER],
    roleTypes: [RolesTypeEnum.ADMIN]
  }
];

const flattenRoutes = (routes: ACLRoutesProps[]): ACLRoutesProps[] => {
  let flatRoutes: ACLRoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

const authProtectedRoutes = [...dashboard];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export { authProtectedFlattenRoutes, publicProtectedFlattenRoutes };
